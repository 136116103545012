body {
  background: #f5f5f5 !important;
}

/* Style color pickers globaly */
input[type='color'] {
  appearance: none;
  border: none;
  width: 3.5rem;
  height: 3.5rem;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

input[type='color']::-webkit-color-swatch-wrapper {
  padding: 0;
}

input[type='color']::-webkit-color-swatch {
  border: none;
  border-radius: 0.5rem 0 0 0.5rem;
}
